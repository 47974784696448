@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: GTPlanarTrial;
    src: url("./assets/fonts/GTPlanarTrial/GT-Planar-Thin-Trial.otf")
      format("opentype");
    font-weight: 100;
  }
  @font-face {
    font-family: GTPlanarTrial;
    src: url("./assets/fonts/GTPlanarTrial/GT-Planar-Light-Trial.otf")
      format("opentype");
    font-weight: 300;
  }
  @font-face {
    font-family: GTPlanarTrial;
    src: url("./assets/fonts/GTPlanarTrial/GT-Planar-Regular-Trial.otf")
      format("opentype");
    font-weight: 400;
  }
  @font-face {
    font-family: GTPlanarTrial;
    src: url("./assets/fonts/GTPlanarTrial/GT-Planar-Medium-Trial.otf")
      format("opentype");
    font-weight: 500;
  }
  @font-face {
    font-family: GTPlanarTrial;
    src: url("./assets/fonts/GTPlanarTrial/GT-Planar-Bold-Trial.otf")
      format("opentype");
    font-weight: 700;
  }
  @font-face {
    font-family: GTPlanarTrial;
    src: url("./assets/fonts/GTPlanarTrial/GT-Planar-Black-Trial.otf")
      format("opentype");
    font-weight: 900;
  }
}

@layer utilities {
  .animate-hover {
    @apply hover:scale-[1.02] transition duration-150;
  }
}

* {
  color: #000;
  font-family: "Roboto", "sans-serif";
}

/* body {
    color: #000;
    font-family: "Be Vietnam Pro", "sans-serif";
    background-color: white;
    overflow-x: hidden;
} */

.div-button {
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;

    transform: scale(1.05);
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.95);
    opacity: 0.6;
  }
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: #fff;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #fff;
  border-radius: 10px;
}

.show {
  display: flex;
}

.dashed-line {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
}

.hover-push-button {
  @apply border hover:border-black bg-white hover:translate-y-[-4px] ease-in-out duration-200 transition hover:shadow-[0px_6px_0px_-4px_#000000];
}

.progress-animation .progress-bar {
  display: block;
  height: 100%;
  width: 100%;
  background-color: #057bc5;
  animation: progress 5.3s linear infinite;
}

@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.header-layout {
  @apply text-[24px] md:text-[32px] xl:text-[40px] 2xl:text-[48px];
}

.mint-benefit-title {
  @apply text-[16px] 2xl:text-xl;
}

.mint-benefit-description {
  @apply text-[14px] 2xl:text-[16px];
}
